<template>
  <div>
    <div v-if="reseller">
      <div class="flex flex-wrap">
        <div class="w-full lg:w-1/5 lg:pr-3">
          <router-link tag="div" :to="'/kunder/reseller/' + reseller_id" class="flex flex-col items-center px-4 py-3 mt-2 text-center cursor-pointer group">
            <BaseIcon icon="users" class="text-6xl group-hover:text-primary-500 transition-all delay-75 duration-300 ease-linear" />
            <div class="font-semibold font-serif mt-3 group-hover:text-primary-500 transition-all delay-75 duration-300 ease-linear">Kunder</div>
          </router-link>
        </div>
        <div class="w-full lg:w-1/5 lg:pr-3">
          <router-link tag="div" :to="'/alarm-center/email-log/reseller/' + reseller_id" class="flex flex-col items-center px-4 py-3 mt-2 text-center cursor-pointer group">
            <BaseIcon icon="business-time" class="text-6xl group-hover:text-primary-500 transition-all delay-75 duration-300 ease-linear" />
            <div class="font-semibold font-serif mt-3 group-hover:text-primary-500 transition-all delay-75 duration-300 ease-linear">Larmunderlag</div>
          </router-link>
        </div>
        <div class="w-full lg:w-1/5 lg:pr-3">
          <router-link tag="div" to="/addon-activate" class="flex flex-col items-center px-4 py-3 mt-2 text-center cursor-pointer group">
            <BaseIcon icon="cube" class="text-6xl group-hover:text-primary-500 transition-all delay-75 duration-300 ease-linear" />
            <div class="font-semibold font-serif mt-3 group-hover:text-primary-500 transition-all delay-75 duration-300 ease-linear">Tilläggstjänster</div>
          </router-link>
        </div>
        <div v-if="reseller.special_access.indexOf('config') > -1" class="w-full lg:w-1/5 lg:pr-3">
          <router-link tag="div" to="/config/alarm" class="flex flex-col items-center px-4 py-3 mt-2 text-center cursor-pointer group">
            <BaseIcon icon="cogs" class="text-6xl group-hover:text-primary-500 transition-all delay-75 duration-300 ease-linear" />
            <div class="font-semibold font-serif mt-3 group-hover:text-primary-500 transition-all delay-75 duration-300 ease-linear">Konfigurera larm</div>
          </router-link>
        </div>
        <div v-if="reseller.special_access.indexOf('alarm_report') > -1" class="w-full lg:w-1/5 lg:pr-3">
          <router-link tag="div" to="/alarm-report/search" class="flex flex-col items-center px-4 py-3 mt-2 text-center cursor-pointer group">
            <BaseIcon icon="list" class="text-6xl group-hover:text-primary-500 transition-all delay-75 duration-300 ease-linear" />
            <div class="font-semibold font-serif mt-3 group-hover:text-primary-500 transition-all delay-75 duration-300 ease-linear">Live larmrapport</div>
          </router-link>
        </div>
        <div v-if="reseller.special_access.indexOf('ac_securtrack') > -1 && reseller.alarm_center" class="w-full lg:w-1/5 lg:pr-3">
          <router-link tag="div" :to="'/alarm-center/securtrack/' + reseller.alarm_center" class="flex flex-col items-center px-4 py-3 mt-2 text-center cursor-pointer group">
            <BaseIcon icon="map-marker-alt" class="text-6xl group-hover:text-primary-500 transition-all delay-75 duration-300 ease-linear" />
            <div class="font-semibold font-serif mt-3 group-hover:text-primary-500 transition-all delay-75 duration-300 ease-linear">SecurTrack</div>
          </router-link>
        </div>
        <div v-if="reseller.special_access.indexOf('ac_securtool') > -1 && reseller.alarm_center" class="w-full lg:w-1/5 lg:pr-3">
          <router-link tag="div" :to="'/alarm-center/securtool/' + reseller.alarm_center" class="flex flex-col items-center px-4 py-3 mt-2 text-center cursor-pointer group">
            <BaseIcon icon="history" class="text-6xl group-hover:text-primary-500 transition-all delay-75 duration-300 ease-linear" />
            <div class="font-semibold font-serif mt-3 group-hover:text-primary-500 transition-all delay-75 duration-300 ease-linear">SecurTool</div>
          </router-link>
        </div>
      </div>
      <div class="flex flex-wrap justify-center lg:justify-start mt-10">
        <div class="w-full md:w-1/2">
          <div class="bg-secondary-200 border border-secondary-300 py-6 px-6">
            <div class="w-full text-md font-bold mb-10">
              <div class="clear-both">
                <div class="float-left">Prislista</div>
                <div class="float-right" v-if="user.role == 'reseller'">
                  <button class="btn-blue font-bold" @click="showResellerOrderModal()">Beställning</button>
                </div>
              </div>
            </div>

            <hr class="my-5" />

            <div>
              <div class="border-b-2 border-gray-200 mb-3">
                <ul class="flex text-sm font-semibold">
                  <li v-for="o in reseller.pricelist" :key="o.id" class="py-2 px-6 bg-gray-50 text-gray-500 rounded-t-lg cursor-pointer" v-bind:class="{ 'bg-gray-200 text-gray-600': o.id == selectedScheme }" @click.prevent="changeSchemeTab(o.id)">
                    <span v-if="o.id == 'cash'">Kontant</span>
                    <span v-else-if="o.id == 'rental'">Hyres</span>
                    <span v-else-if="o.id == 'kickback'">Kickback</span>
                  </li>
                </ul>
              </div>
              <template v-for="o in reseller.pricelist">
                <div v-bind:key="o.id" v-show="o.id == selectedScheme">
                  <template v-for="p in o.prices">
                    <div v-if="p.price != 0" v-bind:key="p.id" class="w-full flex flex-wrap my-1 border-b border-gray-300 px-2">
                      <div class="w-1/2 text-sm">
                        {{ p.name }}
                      </div>
                      <div class="w-1/2">
                        <span class="text-sm">{{ p.price | currency }}</span>
                        <span class="pl-1 text-xs" v-if="p.price_type == 'month'"> / månad</span>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="w-full md:w-1/2 lg:pl-6">
          <div class="flex flex-col">
            <div class="px-4 py-5 flex flex-col border border-secondary-300">
              <div class="clearfix border-b border-secondary-300 pb-2 mb-3">
                <div class="float-left text-lg text-bold font-serif">
                  {{ reseller.name }}
                </div>
                <div class="float-right">
                  <button class="btn-blue-outline font-bold" @click="show('update-reseller')">Ändra</button>
                </div>
              </div>
              <div class="flex flex-wrap text-sm">
                <div class="w-full lg:w-1/3 py-2">
                  <img :src="reseller.logo" class="w-28" />
                </div>
                <div class="w-full lg:w-2/3 py-2">
                  <div class="mb-1">{{ reseller.email }}</div>
                  <div class="mb-1">{{ reseller.phone }}</div>
                  <div class="mb-1">
                    <pre class="font-sans text-xs whitespace-pre-wrap">{{ reseller.description }}</pre>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex flex-wrap border border-secondary-300 text-center mt-6 px-4 py-4">
              <div class="w-full md:w-1/2 lg:w-1/3 px-2 py-2">
                <div class="text-primary-500 font-bold text-2xl mb-2">{{ total_alarm }}</div>
                <div class="font-bold mb-1 font-serif">Antal larm</div>
              </div>
              <div class="w-full md:w-1/2 lg:w-1/3 px-2 py-2">
                <div class="text-primary-500 font-bold text-2xl mb-2">{{ total_customer }}</div>
                <div class="font-bold mb-1 font-serif">Antal kunder</div>
              </div>
              <div class="w-full md:w-1/2 lg:w-1/3 px-2 py-2">
                <div class="text-primary-500 font-bold text-2xl mb-2">{{ total_unit }}</div>
                <div class="font-bold mb-1 font-serif">Antal verksamheter</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ResellerUpdate :resellerInfo="updateInfo" @updateReseller="getResellerDashboard()" />
  </div>
</template>

<script>
import ResellerUpdate from "@/components/modal/reseller_update";

export default {
  name: "DashboardReseller",
  title() {
    return `Återförsäljare | SecurCloud`;
  },
  components: {
    ResellerUpdate,
  },
  data() {
    return {
      user: this.$store.state.user,
      reseller_id: this.$route.params.id,
      reseller: null,
      total_alarm: "-",
      total_customer: "-",
      total_unit: "-",
      selectedScheme: "cash",
      updateInfo: null,
    };
  },
  methods: {
    async getResellerDashboard() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/dashboard/reseller/${this.reseller_id}`);
        this.reseller = response.data.reseller;
        this.total_alarm = response.data.total_alarm;
        this.total_unit = response.data.total_unit;
        this.total_customer = response.data.total_customer;

        if (this.reseller.pricelist.length > 0) this.selectedScheme = this.reseller.pricelist[0].id;

        this.setPageTitle(`${this.reseller.name} - Dashboard`, "reseller_dashboard");
      } catch (error) {
        this.handleError(error);
      }
    },

    changeSchemeTab(id) {
      this.selectedScheme = id;
    },

    show() {
      this.updateInfo = _.cloneDeep(this.reseller);
      this.$modal.show("update-reseller");
    },

    showResellerOrderModal() {
      this.$parent.$refs.resellerOrderAlarm.changeSelectedScheme(this.selectedScheme);
      this.$modal.show("modal-order-alarm-reseller");
    },
  },

  filters: {
    currency: function(value) {
      if (!value) return "";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " kr";
    },
  },

  created() {
    this.getResellerDashboard();
  },
};
</script>
